/* src/style/App.css */
.landing-page {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    background-color: #333;
    padding: 10px 0;
}

nav li {
    margin: 0 15px;
    color: purple;
}

nav a {
    text-decoration: none;
    color: white;
}

footer {
    margin-top: 50px;
    color: #777;
}
