.App {
  text-align: center;
  vertical-align: middle;
}

.sticky-div {
  position: fixed;
    z-index: 1;
}

a {
  color: #309648;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-10px);
  }

  40%,
  80% {
    transform: translateX(10px);
  }
}

/* Apply the shake animation to the button when the 'shake' class is present */
.shake {
  animation: shake 0.5s;
}

@keyframes fadeIn {
		0% {
			transform:translateZ(-800px) rotateX(90deg);
			opacity:0;
		}
		50% {
			transform:translateZ(-160px) rotateX(87deg);
			opacity:1;
		}
		100% {
			transform:translateZ(0) rotateX(0);
		}
	}

   

 




/* .flashing tbody {
  animation: flash 1s infinite;
} */
/* .MuiTableBody-root {
  animation: flash 1s infinite;
} */




/* 
#root {
  display: flex;
  align-items: center;
} */

/* body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

/* form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
} */


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripe-button:hover {
  filter: contrast(115%);
}

.stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.stripe-spinner,
.stripe-spinner:before,
.stripe-spinner:after {
  border-radius: 50%;
}

.stripe-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripe-spinner:before,
.stripe-spinner:after {
  position: absolute;
  content: '';
}

.stripe-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripe-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  background-color: #fafafa;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  border-bottom: 2px solid #e2e8f0;
  outline: 0;
}

.StripeElement--focus {
  border-bottom: 2px solid #01896a;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}